import React from 'react';
import styled, { css } from 'styled-components';

import { BaseFontStyles, FontWeights } from '../../../styles/fonts';
import { Colors } from '../../../styles/colors';

import { InfoIcon } from '../Icons';
import { media } from '../../../styles/media';

export const InfoAlert = ({ children, icon, type = AlertType.Info, isSmall, color, ...otherProps }: InfoAlertProps) => {
  let currentIcon = icon || <InfoIcon size={2} />;
  return (
    <Container color={color} $isSmall={isSmall} type={type} {...otherProps}>
      <IconContainer type={type}>{currentIcon}</IconContainer>
      <MessageContainer>{children}</MessageContainer>
    </Container>
  );
};

export enum AlertType {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Custom = 'custom',
}

const Container = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 16px 8px;
  border-radius: 4px;
  flex-wrap: nowrap;
  justify-content: flex-start;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      padding: 4px 8px;
    `}

  ${({ type, color }) => {
    switch (type) {
      case AlertType.Info:
        return css`
          background-color: ${Colors.Primary};
          border-color: ${Colors.PrimaryDarkest};
          color: ${Colors.Contrast};
        `;
      case AlertType.Warning:
        return css`
          background-color: ${Colors.Warning};
          border-color: ${Colors.WarningLight};
        `;
      case AlertType.Error:
        return css`
          background-color: ${Colors.Error};
          border-color: ${Colors.ErrorLight};
        `;
      case AlertType.Custom:
        if (color) {
          return css`
            background-color: ${color};
            border-color: transparent;
          `;
        }
    }
  }}
`;

const IconContainer = styled.div<StyledProps>`
  ${({ type }) => {
    switch (type) {
      case AlertType.Info:
        return css`
          svg {
            color: ${Colors.White};
          }
        `;
      case AlertType.Warning:
        return css`
          svg {
            color: ${Colors.WarningDark};
          }
        `;
      case AlertType.Error:
        return css`
          svg {
            color: ${Colors.ErrorDark};
          }
        `;
      case AlertType.Custom:
        return css`
          svg {
            color: ${Colors.White};
          }
        `;
    }
  }};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px 8px;
  ${BaseFontStyles}
  line-height: 24px;
  font-weight: ${FontWeights.Regular};
  ${media.from_laptop`
    display: block;
    flex-direction: row;
    align-items: center;
  `}
`;

interface StyledProps {
  type: AlertType;
  $isSmall?: boolean;
}

export interface InfoAlertProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.ReactNode;
  children?: React.ReactNode;
  type?: AlertType;
  isSmall?: boolean;
  color?: string;
}
